import { useQuery } from 'react-query'

import {
    Container,
    ListContainer,
    Wrapper,
    Image,
    DescriptionWrapper,
    Description,
    Title,
    Date,
} from './styles'

const Feed = () => {
    const images = [
        // '001', '002', '003', '004', '005', '006', '007', '008', '009', '010',
        // '011', '012', '013', '014', '015', '016', '017', '018', '019', '020',
        // '021', '022', '023', '024', '025', '026', '027', '028', '029', '030',
        // '031', '032', '033', '034', '035', '036', '037', '038', '229', '230',
        // '231', '232'
    ]
    return (
        <Container initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
            <ListContainer className="no-scroll">
            { 
                images.map(image => {
                    return (
                        <Wrapper key={image}>
                            <Image img={`/feed/image00${image}-min.jpeg`} />
                        </Wrapper>
                    )
                }) 
            }
            </ListContainer>
        </Container>
    )
}

export default Feed